import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["videoContainer"];

  play(event) {
    event.preventDefault();
    const videoId = this.videoContainerTarget.dataset.videoId;

    if (!videoId) return;

    this.videoContainerTarget.innerHTML = `
      <iframe class="w-full h-full aspect-video" width="560" height="315" 
        src="https://www.youtube.com/embed/${videoId}?autoplay=1" 
        title="YouTube video player" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
      </iframe>
    `;
  }
}
