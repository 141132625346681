import { Datepicker } from "stimulus-datepicker";
import { createPopper } from "@popperjs/core";

export default class extends Datepicker {
  static values = {
    positioningEnabled: { type: Boolean, default: true },
  };

  disconnect() {
    this.destroyPopperInstance();
  }

  // -------------------------------------------------------------------
  // Popper Instance Management
  // -------------------------------------------------------------------

  createPopperInstance() {
    if (!this.inputTarget || !this.calendarTarget) return;

    this.popperInstance = createPopper(this.inputTarget, this.calendarTarget, {
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 12],
          },
        },
        {
          name: "preventOverflow",
          options: {
            rootBoundary: "viewport",
            altAxis: true,
            padding: 12,
          },
        },
        {
          name: "computeStyles",
          options: {
            gpuAcceleration: false,
          },
        },
      ],
    });
  }

  destroyPopperInstance() {
    if (this.popperInstance && this.positioningEnabledValue) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  }

  // -------------------------------------------------------------------
  // Animation & Visibility Management
  // -------------------------------------------------------------------

  hideElementWithAnimation(element) {
    element.classList.add("hide-calendar");

    const onAnimationEnd = (e) => {
      e.target.remove();
    };

    if (this.hasCssAnimation(element)) {
      element.onanimationend = onAnimationEnd;
    } else {
      element.remove();
    }
  }

  animateCalendar() {
    this.calendarTarget.classList.add("opacity-0");

    setTimeout(() => {
      this.calendarTarget.classList.remove("opacity-0");
    }, 120);

    this.calendarTarget.classList.add("fade-in");
    this.calendarTarget.onanimationend = () =>
      this.calendarTarget.classList.remove("fade-in");
  }

  // -------------------------------------------------------------------
  // Datepicker Management
  // -------------------------------------------------------------------

  closeInnerDatepicker() {
    const innerDatepicker = document.querySelectorAll(
      'div[data-datepicker-target="calendar"]'
    );

    if (innerDatepicker.length === 0) return;

    innerDatepicker.forEach((datepicker) => {
      this.hideElementWithAnimation(datepicker);

      const datepickerButton = datepicker.parentElement.querySelector(
        'button[data-datepicker-target="toggle"]'
      );

      if (!datepickerButton) return;

      this.styleToggleButton(datepickerButton, false);
    });
  }

  resetValue() {
    this.dateValue = "";
    this.inputTarget.value = "";
  }

  // -------------------------------------------------------------------
  // Opening & Closing the Calendar
  // -------------------------------------------------------------------

  handleOpen(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.hasCalendarTarget) return;

    this.open(true);
  }

  open(animate, isoDate = this.initialIsoDate()) {
    this.styleToggleButton(this.toggleTarget, true);
    this.closeInnerDatepicker();
    super.open(animate, isoDate);

    if (this.positioningEnabledValue) {
      this.createPopperInstance();
      this.popperInstance.update();
    } else {
      this.setPositioningClasses();
    }

    if (animate) {
      this.animateCalendar();
    }
  }

  close(animate) {
    this.styleToggleButton(this.toggleTarget, false);

    if (animate) {
      this.hideElementWithAnimation(this.calendarTarget);
    } else {
      this.calendarTarget.remove();
    }
  }

  // -------------------------------------------------------------------
  // Styling Helper Functions
  // -------------------------------------------------------------------

  setPositioningClasses() {
    this.calendarTarget.classList.add("absolute", "mt-2");
  }

  styleToggleButton(target, open) {
    target.classList.toggle("text-blue-500", open);
    target.classList.toggle("text-gray-500", !open);
  }
}
