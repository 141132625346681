import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "sendPrButton",
    "sendDeploymentLinkButton",
    "finishButton",
    "checkbox",
  ];
  static values = {
    taskStatus: String,
    taskVariant: String,
    userSentPr: Boolean,
    userSentDeployment: Boolean,
  };

  connect() {
    if (this.taskStatusValue === "finished") return this.finishedTask();

    const taskMap = {
      task: this.task.bind(this),
      deployment: this.deploymentTask.bind(this),
    };

    const taskFunction = taskMap[this.taskVariantValue];

    if (!taskFunction) return;

    taskFunction();
  }

  finishedTask() {
    this.setCheckboxesState(true, true);

    if (!this.hasFinishButtonTarget) return;

    this.finishButtonTarget.disabled = false;
  }

  task() {
    const allChexboxesChecked = this.allCheckboxesChecked();

    if (this.userSentPrValue) {
      this.setCheckboxesState(true, true);
    } else {
      this.sendPrButtonTarget.disabled = !allChexboxesChecked;
      this.setCheckboxesState(false);
    }
  }

  deploymentTask() {
    if (!this.userSentDeploymentValue) {
      this.sendDeploymentLinkButtonTarget.disabled =
        !this.allCheckboxesChecked();
      this.setCheckboxesState(false);

      return;
    }

    if (this.userSentDeploymentValue) {
      this.finishButtonTarget.disabled = false;
      this.setCheckboxesState(true, true);
    }
  }

  toggleButtons() {
    const button = this.getButtonByVariant();

    if (!button) return;

    button.disabled = !this.allCheckboxesChecked();
  }

  // * Get the button by variant in this way to prevent an error when the button is not present
  getButtonByVariant() {
    let button;

    switch (this.taskVariantValue) {
      case "task":
        button = this.sendPrButtonTarget;
        break;
      case "deployment":
        button = this.sendDeploymentLinkButtonTarget;
        break;
    }

    return button;
  }

  allCheckboxesChecked() {
    return this.checkboxTargets.every((x) => x.checked);
  }

  setCheckboxesState = (disabled, checked = false) => {
    this.checkboxTargets.forEach((x) => {
      x.disabled = disabled;
      if (checked !== false) {
        x.checked = checked;
      }
    });
  };
}
